// If you want to override variables do it here
@import "./netsul_variables.scss";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "./custom";

@font-face {
  font-family: "Kabrio-Bold";
  src: url("../../assets/fonts/Kabrio-Bold-trial.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
