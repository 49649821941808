// Variable overrides

$primary: #3b683d !default;

$secondary: #f3f3f1 !default;

$danger: #a51313 !default;

$info: #00adb8 !default;

$success: #13a51d !default;

$success-dark: #0d8d22 !default;

$warning: #ee9032 !default;

$light: #f9f9f9 !default;
$dark: #0b311a !default;

$border-radius: 3px;

$font-size-base: 0.938rem;
